/* eslint-disable class-methods-use-this */
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { B3Propagator } from '@opentelemetry/propagator-b3';
import { Resource } from '@opentelemetry/resources';
import {
  ConsoleSpanExporter, SimpleSpanProcessor, BatchSpanProcessor,
} from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { ATTR_SERVICE_NAME, ATTR_SERVICE_VERSION } from '@opentelemetry/semantic-conventions';
import pkg from '../package.json';
import { useStore } from './use';

const baseUrl = process.env.VUE_APP_OTELURL ?? 'http://localhost:4317';
const store = useStore();
const isProduction = process.env.NODE_ENV === 'production';

class CustomSpanProcessor {
  onStart(span) {
    const username = store.getters['auth/getUsername'] ?? 'anonymous';
    span.setAttribute('username', username);
  }

  onEnd(span) {
    const username = store.getters['auth/getUsername'] ?? 'anonymous';
    span.setAttribute('username', username);

    // Additional logic can be added here if needed
  }

  shutdown() {
    return Promise.resolve();
  }

  forceFlush() {
    return Promise.resolve();
  }
}

const exporter = new OTLPTraceExporter({
  url: `${baseUrl}/v1/traces`,
  concurrencyLimit: 10,
});

const provider = new WebTracerProvider({
  resource: new Resource({
    [ATTR_SERVICE_NAME]: pkg.name,
    [ATTR_SERVICE_VERSION]: pkg.version,
  }),
});
const customSpanProcessor = new CustomSpanProcessor();
provider.addSpanProcessor(customSpanProcessor);

if (isProduction) {
  provider.addSpanProcessor(new BatchSpanProcessor(exporter));
} else {
  provider.addSpanProcessor(new SimpleSpanProcessor(exporter));
  provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
}
provider.register({
  contextManager: new ZoneContextManager(),
  propagator: new B3Propagator(),
});
const addCustomAttributesToSpan = (span) => {
  const username = store.getters['auth/getUsername'] ?? 'anonymous';
  span.setAttribute('username', username);
};
const addCustomAttributesToResourceFetchSpan = (span, resource) => {
  const username = store.getters['auth/getUsername'] ?? 'anonymous';
  span.setAttribute('username', username);
  span.setAttribute('deliveryType', resource.deliveryType);
  span.setAttribute('initiatorType', resource.initiatorType);
  span.setAttribute('resource.tcp.duration_ms', resource.connectEnd - resource.connectStart);
};

const addCustomAttributesToDocumentFetch = (span, resource) => {
  const username = store.getters['auth/getUsername'] ?? 'anonymous';
  span.setAttribute('username', username);
  span.setAttribute('deliveryType', resource.deliveryType);
  span.setAttribute('initiatorType', resource.initiatorType);
  span.setAttribute('resource.tcp.duration_ms', resource.connectEnd - resource.connectStart);
};

registerInstrumentations({
  instrumentations: [
    new XMLHttpRequestInstrumentation({
      ignoreUrls: [],
      ignoreNetworkEvents: [
        'loaded',
        'responseEnd',
        'fetchStart',
        'send',
      ],
      applyCustomAttributesOnSpan: (span, request) => {
        const username = store.getters['auth/getUsername'] ?? 'anonymous';
        span.setAttribute('username', username);
        span.setAttribute('entryType', request.entryType);
        span.setAttribute('url', request.url);
        span.setAttribute('cache', request.cache);
        span.setAttribute('mode', request.mode);
      },
    }),
    new DocumentLoadInstrumentation({
      ignoreNetworkEvents: [
        'connectEnd',
        'connectStart',
        'decodedBodySize',
        'domainLookupEnd',
        'domainLookupStart',
        'encodedBodySize',
        'loadEventEnd',
        'loadEventStart',
        'navigationStart',
        'redirectEnd',
        'redirectStart',
        'requestStart',
        'responseEnd',
        'responseStart',
        'secureConnectionStart',
      ],
      ignorePerformancePaintEvents: true,
      applyCustomAttributesOnSpan: {
        documentLoad: addCustomAttributesToSpan,
        documentFetch: addCustomAttributesToDocumentFetch,
        resourceFetch: addCustomAttributesToResourceFetchSpan,
      },
    }),
  ],
});
