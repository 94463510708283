import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('packingListPallet', 'packingListPallets');

const customActions = {
  async LOAD_packingListPallets({ commit, rootGetters }, packingListId) {
    const data = await odata.o(rootGetters)
      .get('packingListPallets')
      .query({ $filter: `IsDeleted eq false AND PackingListId eq ${packingListId}` });
    commit('SET_packingListPallets', data);

    return data;
  },
};

export default { ...actions, ...customActions };
