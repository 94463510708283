<template>
  <BasicDialog :icon="mdiViewColumn" title="Columns" hide-label>
    <template #content>
      <draggable v-model="localColumns" :options="{ handle: '.drag-handle' }" @end="saveColumnOrder">
        <template slot="header">
          <div class="d-flex justify-space-between align-center justify-center">
            <v-autocomplete
              v-model="newColumn"
              label="Add column"
              :items="allHeaders"
              return-object
            ></v-autocomplete>
            <v-btn
              color="success"
              class="ml-2"
              small
              style="border: 1px solid #3f3f3f"
              :disabled="!newColumn"
              @click="addColumn"
            >
              <v-icon>{{ mdiPlus }}</v-icon>
            </v-btn>
          </div>
        </template>
        <div v-for="column in localColumns" :id="column.value" :key="column.text" class="d-flex border mb-2">
          <v-icon class="drag-handle">
            {{ mdiMenu }}
          </v-icon>
          <div class="text-h6 mt-1 ml-1">
            {{ column.text }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="localColumns.splice(localColumns.indexOf(column), 1)">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </div>
      </draggable>
    </template>
    <template #actions-left>
      <v-btn
        v-if="baseHeaders.length > 0"
        color="warning"
        title="Reset the columns to defaults"
        @click="resetHeaders"
      >
        Reset
      </v-btn>
    </template>
  </BasicDialog>
</template>

<script>
import {
  mdiViewColumn, mdiClose, mdiMenu, mdiPlus,
} from '@mdi/js';
import draggable from 'vuedraggable';
import BasicDialog from '@/components/BasicDialog.vue';

export default {
  name: 'ColumnDialog',
  components: {
    BasicDialog,
    draggable,
  },
  props: {
    allHeaders: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    baseHeaders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mdiClose,
      mdiMenu,
      mdiViewColumn,
      mdiPlus,
      columnDialog: false,
      localColumns: this.headers,
      newColumn: null,
    };
  },
  watch: {
    localColumns: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('update:headers', newValue);
      },
    },
  },
  methods: {
    addColumn() {
      this.localColumns.unshift(this.newColumn);
      this.newColumn = null;
    },
    async resetHeaders() {
      const res = await this.$confirm('This will reset the columns to the default. Continue?');
      if (!res) return;

      this.localColumns = [...this.baseHeaders];
    },
    saveColumnOrder() {
      this.$emit('update:headers', this.localColumns);
    },
  },
};
</script>
