import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('product', 'products');

const customActions = {
  async LOAD_productsMin({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'Brand,ProductGroup',

        // $select: 'Id,Brand,ProductGroup,Price,UnitBarcode,NappiCode,PipCode',
      });
    commit('SET_products', data);

    return data;
  },

  async LOAD_tradePriceList({ rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products')
      .query({
        $filter: 'IsDeleted eq false AND hasPricelist eq true',
        $expand: 'Brand,ProductGroup',
      });

    return data;
  },
};

export default { ...actions, ...customActions };
